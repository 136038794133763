// TestTaking.js

import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { ProgressBar, Button, Form } from 'react-bootstrap';
import Header from '../layouts/Header';
import Footer from '../layouts/Footer';
import '../assets/css/TestTaking.css'; // Custom CSS

function TestTaking() {
  const { testId } = useParams();
  const navigate = useNavigate();

  const [isTimed, setIsTimed] = useState(false);
  const [timeLimit, setTimeLimit] = useState(30); // in minutes
  const [timeRemaining, setTimeRemaining] = useState(timeLimit * 60); // in seconds
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);

  const [questions, setQuestions] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [currentQuestion, setCurrentQuestion] = useState(null);
  const [userAnswers, setUserAnswers] = useState({});

  useEffect(() => {
    document.title = 'Test Taking | YouElite';
  }, []);

  useEffect(() => {
    // Mock data for questions
    const mockQuestions = [
      {
        id: 1,
        content: 'What is the atomic number of Helium?',
        answers: [
          { id: 1, content: '1' },
          { id: 2, content: '2' },
          { id: 3, content: '3' },
          { id: 4, content: '4' },
        ],
      },
      // Add more questions as needed
    ];
    setQuestions(mockQuestions);
    setCurrentQuestion(mockQuestions[0]);

    // Check if the test is timed
    setIsTimed(true); // Set based on actual test data
    if (isTimed) {
      setTimeRemaining(timeLimit * 60);
    }
  }, []);

  useEffect(() => {
    if (isTimed) {
      startTimer();
    }
    // Clean up timer on unmount
    return () => {
      clearInterval(timerRef.current);
    };
  }, [isTimed]);

  const timerRef = React.useRef(null);

  const startTimer = () => {
    setMinutes(Math.floor(timeRemaining / 60));
    setSeconds(timeRemaining % 60);

    timerRef.current = setInterval(() => {
      setTimeRemaining((prevTime) => {
        if (prevTime <= 1) {
          clearInterval(timerRef.current);
          submitTest();
          return 0;
        } else {
          const newTime = prevTime - 1;
          setMinutes(Math.floor(newTime / 60));
          setSeconds(newTime % 60);
          return newTime;
        }
      });
    }, 1000);
  };

  const handleAnswerChange = (e) => {
    const { name, value } = e.target;
    setUserAnswers((prevAnswers) => ({
      ...prevAnswers,
      [name]: parseInt(value),
    }));
  };

  const nextQuestion = () => {
    if (currentQuestionIndex < questions.length - 1) {
      const newIndex = currentQuestionIndex + 1;
      setCurrentQuestionIndex(newIndex);
      setCurrentQuestion(questions[newIndex]);
    } else {
      submitTest();
    }
  };

  const submitTest = () => {
    clearInterval(timerRef.current);
    // Process userAnswers and navigate to result page
    navigate(`/test/${testId}/result`);
  };

  return (
    <React.Fragment>
      <Header />
      <div className="main main-app p-3 p-lg-4">
        <div className="content-wrapper">
          <h4 className="main-title mb-4">Test {testId}</h4>
          {isTimed && (
            <ProgressBar
              now={(timeRemaining / (timeLimit * 60)) * 100}
              label={`Time Remaining: ${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`}
              className="mb-3"
            />
          )}
          {currentQuestion ? (
            <div>
              <h5>
                Question {currentQuestionIndex + 1} of {questions.length}
              </h5>
              <p>{currentQuestion.content}</p>
              <Form>
                {currentQuestion.answers.map((answer) => (
                  <Form.Check
                    type="radio"
                    key={answer.id}
                    label={answer.content}
                    name={`question-${currentQuestion.id}`}
                    value={answer.id}
                    checked={userAnswers[currentQuestion.id] === answer.id}
                    onChange={handleAnswerChange}
                    className="mb-2"
                  />
                ))}
              </Form>
              <Button variant="primary" onClick={nextQuestion} className="mt-3">
                Next
              </Button>
            </div>
          ) : (
            <p>Loading questions...</p>
          )}
        </div>
      </div>
      <Footer />
    </React.Fragment>
  );
}

export default TestTaking;
