// TestResult.js

import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Container, ListGroup, Badge, Button } from 'react-bootstrap';
import Header from '../layouts/Header';
import Footer from '../layouts/Footer';
import '../assets/css/TestResult.css'; // Custom CSS

function TestResult() {
  const { testId } = useParams();

  const [score, setScore] = useState(80); // Mock score
  const [questions, setQuestions] = useState([]);
  const [userAnswers, setUserAnswers] = useState({});

  useEffect(() => {
    document.title = 'Test Results | YouElite';
  }, []);

  useEffect(() => {
    // Mock data for questions and userAnswers
    const mockQuestions = [
      {
        id: 1,
        content: 'What is the atomic number of Helium?',
        answers: [
          { id: 1, content: '1', isCorrect: false },
          { id: 2, content: '2', isCorrect: true },
          { id: 3, content: '3', isCorrect: false },
          { id: 4, content: '4', isCorrect: false },
        ],
      },
      // More questions
    ];
    const mockUserAnswers = {
      1: 1, // User selected answer ID 1 for question ID 1
      // More user answers
    };
    setQuestions(mockQuestions);
    setUserAnswers(mockUserAnswers);
  }, []);

  const getUserAnswer = (questionId) => {
    const answerId = userAnswers[questionId];
    const question = questions.find((q) => q.id === questionId);
    const answer = question.answers.find((a) => a.id === answerId);
    return answer ? answer.content : 'No Answer';
  };

  const getCorrectAnswer = (question) => {
    const correctAnswer = question.answers.find((a) => a.isCorrect);
    return correctAnswer ? correctAnswer.content : 'No Correct Answer';
  };

  const isAnswerCorrect = (questionId) => {
    const question = questions.find((q) => q.id === questionId);
    const userAnswerId = userAnswers[questionId];
    const userAnswer = question.answers.find((a) => a.id === userAnswerId);
    return userAnswer ? userAnswer.isCorrect : false;
  };

  const redoIncorrect = () => {
    // Filter out incorrect questions and start a new test
    alert('Redo incorrect questions functionality to be implemented.');
  };

  return (
    <React.Fragment>
      <Header />
      <div className="main main-app p-3 p-lg-4">
        <div className="content-wrapper">
          <h4 className="main-title mb-4">Test Results</h4>
          <h5>
            Your Score: <Badge bg="success">{score}%</Badge>
          </h5>
          <ListGroup className="mt-4">
            {questions.map((question) => (
              <ListGroup.Item key={question.id}>
                <p>
                  <strong>{question.content}</strong>
                </p>
                <p>Your Answer: {getUserAnswer(question.id)}</p>
                <p>Correct Answer: {getCorrectAnswer(question)}</p>
                {isAnswerCorrect(question.id) ? (
                  <Badge bg="success">Correct</Badge>
                ) : (
                  <Badge bg="danger">Incorrect</Badge>
                )}
              </ListGroup.Item>
            ))}
          </ListGroup>
          <Button variant="warning" onClick={redoIncorrect} className="mt-3">
            Redo Incorrect Questions
          </Button>
        </div>
      </div>
      <Footer />
    </React.Fragment>
  );
}

export default TestResult;
