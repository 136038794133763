// CourseList.js

import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Card,
  Button,
  Row,
  Col,
  ProgressBar,
  Badge,
  ListGroup,
  Modal,
  Form,
} from 'react-bootstrap';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto'; // Important for Chart.js version 3
import Header from '../layouts/Header';
import Footer from '../layouts/Footer';
import '../assets/css/CourseList.css'; // Adjusted CSS import
import EmojiPicker from 'emoji-picker-react'; // Emoji picker component

function CourseList() {
  useEffect(() => {
    document.title = 'Your Courses | Study Helper';
  }, []);

  // State variables
  const [courses, setCourses] = useState([
    { id: 1, name: 'AP Chemistry', icon: '🧪' },
    { id: 2, name: 'AP Physics', icon: '🔭' },
    // Add more courses as needed
  ]);
  const [showModal, setShowModal] = useState(false);
  const [newCourseTitle, setNewCourseTitle] = useState('');
  const [newCourseIcon, setNewCourseIcon] = useState('📘');
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);

  // Essential gamification features (mock data)
  const userLevel = 5;
  const currentXP = 350;
  const xpForNextLevel = 500;
  const xpProgress = (currentXP / xpForNextLevel) * 100;

  const badges = [
    { name: 'Quiz Master', description: 'Completed 10 tests', icon: '🎓' },
    { name: 'Perfectionist', description: 'Scored 100% on a test', icon: '🌟' },
    // Show only essential badges
  ];

  // Enhanced analytics data (mock data)
  const mostMissedTopics = [
    { topic: 'Thermodynamics', timesMissed: 5 },
    { topic: 'Stoichiometry', timesMissed: 3 },
  ];

  const bestTopics = [
    { topic: 'Kinematics', timesCorrect: 8 },
    { topic: 'Electromagnetism', timesCorrect: 6 },
  ];

  const weakestCourse = { name: 'AP Chemistry', averageScore: 65 };
  const overallAverage = 78; // Overall average score
  const totalTestsTaken = 15;

  // Mock data for performance trends chart
  const performanceData = {
    labels: ['Test 1', 'Test 2', 'Test 3', 'Test 4'],
    datasets: [
      {
        label: 'Performance',
        data: [70, 75, 80, 85],
        backgroundColor: 'rgba(52, 152, 219, 0.2)',
        borderColor: 'rgba(52, 152, 219, 1)',
        borderWidth: 2,
        fill: true,
      },
    ],
  };

  const performanceOptions = {
    scales: {
      y: {
        beginAtZero: true,
        max: 100,
      },
    },
    maintainAspectRatio: false,
  };

  const openModal = () => {
    setShowModal(true);
    setNewCourseTitle('');
    setNewCourseIcon('📘');
  };

  const closeModal = () => {
    setShowModal(false);
    setShowEmojiPicker(false);
  };

  const handleEmojiClick = (emojiObject) => {
    setNewCourseIcon(emojiObject.emoji);
    setShowEmojiPicker(false);
  };

  const handleSaveCourse = () => {
    // For now, we'll just close the modal and pretend to save
    // You can implement the actual save functionality when integrating with the backend
    const newCourse = {
      id: courses.length + 1,
      name: newCourseTitle || 'New Course',
      icon: newCourseIcon,
    };
    setCourses([...courses, newCourse]);
    closeModal();
  };

  return (
    <React.Fragment>
      <Header />
      <div className="main main-app p-3 p-lg-4">
        <div className="content-wrapper">
          <Row className="mb-4">
            <Col>
              <h4 className="main-title mb-0">Your Courses</h4>
            </Col>
          </Row>
          <Row>
            {/* Main Content: Course List */}
            <Col md={8}>
              <Button variant="success" onClick={openModal} className="mb-4">
                Create New Course
              </Button>
              <Row>
                {courses.map((course) => (
                  <Col xs={12} sm={6} md={6} lg={4} key={course.id} className="mb-4">
                    <Card className="h-100 course-card text-center">
                      <Card.Body className="d-flex flex-column">
                        <div className="course-icon mb-3">{course.icon}</div>
                        <Card.Title className="mb-3">{course.name}</Card.Title>
                        <div className="mt-auto">
                          <Link to={`/courses/${course.id}`}>
                            <Button variant="primary" className="w-100">
                              View Course
                            </Button>
                          </Link>
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                ))}
              </Row>
            </Col>

            {/* Sidebar: Gamification and Analytics */}
            <Col md={4}>
              {/* Level, XP Progress, and Badges */}
              <Card className="mb-4">
                <Card.Header>
                  <Card.Title as="h5" className="mb-0">
                    Level {userLevel}
                  </Card.Title>
                </Card.Header>
                <Card.Body>
                  <p className="text-center">
                    XP: {currentXP}/{xpForNextLevel}
                  </p>
                  <ProgressBar
                    now={xpProgress}
                    label={`${Math.floor(xpProgress)}%`}
                    className="mb-3"
                  />
                  <h6 className="text-center">Badges</h6>
                  <div className="badges-container">
                    {badges.map((badge, index) => (
                      <div key={index} className="badge-item">
                        <h1>{badge.icon}</h1>
                        <p>{badge.name}</p>
                      </div>
                    ))}
                  </div>
                </Card.Body>
              </Card>

              {/* Analytics and Performance Trends */}
              <Card>
                <Card.Header>
                  <Card.Title as="h5">Analytics</Card.Title>
                </Card.Header>
                <Card.Body>
                  {/* Overall Average Score */}
                  <h6>Overall Average Score</h6>
                  <p>
                    <Badge bg="info">{overallAverage}%</Badge>
                  </p>

                  {/* Total Tests Taken */}
                  <h6>Total Tests Taken</h6>
                  <p>
                    <Badge bg="secondary">{totalTestsTaken}</Badge>
                  </p>

                  {/* Most Missed Topics */}
                  <h6>Most Missed Topics</h6>
                  <ListGroup className="mb-3">
                    {mostMissedTopics.map((topic, index) => (
                      <ListGroup.Item key={index}>
                        {topic.topic}{' '}
                        <Badge bg="danger">{topic.timesMissed} mistakes</Badge>
                      </ListGroup.Item>
                    ))}
                  </ListGroup>

                  {/* Best Performing Topics */}
                  <h6>Best Topics</h6>
                  <ListGroup className="mb-3">
                    {bestTopics.map((topic, index) => (
                      <ListGroup.Item key={index}>
                        {topic.topic}{' '}
                        <Badge bg="success">{topic.timesCorrect} correct</Badge>
                      </ListGroup.Item>
                    ))}
                  </ListGroup>

                  {/* Weakest Course */}
                  <h6>Weakest Course</h6>
                  <p>
                    {weakestCourse.name} with an average score of{' '}
                    <Badge bg="warning" text="dark">
                      {weakestCourse.averageScore}%
                    </Badge>
                  </p>

                  {/* Performance Trends Chart */}
                  <div style={{ height: '200px' }}>
                    <Line data={performanceData} options={performanceOptions} />
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </div>
        <Footer className="footer" />
      </div>

      {/* Modal for Creating New Course */}
      <Modal show={showModal} onHide={closeModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Create New Course</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formCourseTitle" className="mb-3">
              <Form.Label>Course Title</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter course title"
                value={newCourseTitle}
                onChange={(e) => setNewCourseTitle(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="formCourseIcon" className="mb-3">
              <Form.Label>Course Icon</Form.Label>
              <div className="d-flex align-items-center">
                <Button
                  variant="outline-secondary"
                  onClick={() => setShowEmojiPicker(!showEmojiPicker)}
                  className="me-2"
                >
                  {newCourseIcon}
                </Button>
                <Form.Text>Select an emoji for your course.</Form.Text>
              </div>
              {showEmojiPicker && (
                <div className="emoji-picker">
                  <EmojiPicker onEmojiClick={handleEmojiClick} />
                </div>
              )}
            </Form.Group>
            <Button variant="primary" onClick={handleSaveCourse}>
              Save
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
}

export default CourseList;
