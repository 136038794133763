import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import {
  Card,
  Button,
  Row,
  Col,
  ListGroup,
  Form,
  Badge,
  Collapse,
} from 'react-bootstrap';
import Header from '../layouts/Header';
import Footer from '../layouts/Footer';
import '../assets/css/CourseDetail.css'; // Custom CSS for this component
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';

function CourseDetail() {
  const { courseId } = useParams();
  const [course, setCourse] = useState(null);
  const [notes, setNotes] = useState([]);
  const [analyticsData, setAnalyticsData] = useState({});
  const [mistakes, setMistakes] = useState([]);
  const [numQuestionsForTest, setNumQuestionsForTest] = useState(5);
  const hasPerformanceData = analyticsData.performanceData && analyticsData.performanceData.labels && analyticsData.performanceData.datasets;

  useEffect(() => {
    const mockCourse = {
      id: courseId,
      name: 'AP Chemistry',
      description: 'Advanced placement chemistry course.',
    };
    setCourse(mockCourse);

    const mockNotes = [
      { id: 1, title: 'Chapter 1 Notes', type: 'Text' },
      { id: 2, title: 'Chemical Equations', type: 'PDF' },
    ];
    setNotes(mockNotes);

    const mockAnalyticsData = {
      averageScore: 80,
      testsTaken: 10,
      performanceData: {
        labels: ['Test 1', 'Test 2', 'Test 3', 'Test 4'],
        datasets: [
          {
            label: 'Your Score',
            data: [70, 75, 85, 90],
            backgroundColor: 'rgba(52, 152, 219, 0.2)',
            borderColor: 'rgba(52, 152, 219, 1)',
            borderWidth: 2,
            fill: true,
          },
        ],
      },
      mostMissedQuestions: [
        { question: 'What is the atomic number of Helium?', timesMissed: 3 },
        { question: 'Define enthalpy.', timesMissed: 2 },
      ],
      topicsPerformance: [
        { topic: 'Thermodynamics', score: 60 },
        { topic: 'Stoichiometry', score: 85 },
      ],
    };
    setAnalyticsData(mockAnalyticsData);

    const mockMistakes = [
      {
        id: 1,
        question: 'What is the atomic number of Helium?',
        type: 'Multiple Choice',
        difficulty: 'Easy',
        timesMissed: 3,
      },
      {
        id: 2,
        question: 'Define enthalpy.',
        type: 'Short Answer',
        difficulty: 'Medium',
        timesMissed: 2,
      },
    ];
    setMistakes(mockMistakes);
  }, [courseId]);

  const handleNotesSave = () => {
    console.log('Notes saved:', notes);
  };

  const handleCreateTest = () => {
    console.log('Creating test with', numQuestionsForTest, 'questions');
  };

  return (
    <React.Fragment>
      <Header />
      <div className="main main-app p-3 p-lg-4 course-detail-page">
        <div className="content-wrapper">
          <Row>
            {/* Main Content */}
            <Col md={8}>
              {course && (
                <div>
                  <h4 className="main-title mb-4">{course.name}</h4>
                  {/* Course Description and Create Test Button */}
                  <Card className="mb-4">
                    <Card.Body>
                      <Card.Text>{course.description}</Card.Text>
                      <Button
                        variant="primary"
                        as={Link}
                        to={`/courses/${course.id}/test-creation`}
                      >
                        Create Test
                      </Button>
                    </Card.Body>
                  </Card>

                  {/* Notes Section */}
                  <Card className="mb-4">
                    <Card.Header>
                      <Card.Title as="h5">Your Notes</Card.Title>
                    </Card.Header>
                    <Card.Body>
                      <ListGroup>
                        {notes.map((note) => (
                          <ListGroup.Item key={note.id}>
                            <div className="d-flex justify-content-between">
                              <span>{note.title}</span>
                              <Badge bg="info">{note.type}</Badge>
                            </div>
                          </ListGroup.Item>
                        ))}
                      </ListGroup>
                      <Button variant="primary" className="mt-3" onClick={handleNotesSave}>
                        Upload New Note
                      </Button>
                    </Card.Body>
                  </Card>

                  {/* Review Previous Mistakes */}
                  {mistakes.length > 0 && (
                    <Card className="mb-4">
                      <Card.Header>
                        <Card.Title as="h5">Review Previous Mistakes</Card.Title>
                      </Card.Header>
                      <Card.Body>
                        <ListGroup className="mb-4">
                          {mistakes.map((mistake) => (
                            <ListGroup.Item key={mistake.id}>
                              <div className="d-flex justify-content-between">
                                <span>{mistake.question}</span>
                                <Badge bg="warning">
                                  {mistake.type} | {mistake.difficulty} | Missed {mistake.timesMissed} times
                                </Badge>
                              </div>
                            </ListGroup.Item>
                          ))}
                        </ListGroup>
                        <Form>
                          <Form.Group controlId="numQuestionsForTest">
                            <Form.Label>Select number of questions for the new test</Form.Label>
                            <Form.Control
                              as="select"
                              value={numQuestionsForTest}
                              onChange={(e) => setNumQuestionsForTest(Number(e.target.value))}
                            >
                              {[5, 10, 15].map((num) => (
                                <option key={num} value={num}>
                                  {num} questions
                                </option>
                              ))}
                            </Form.Control>
                          </Form.Group>
                        </Form>
                        <Button variant="primary" className="mt-3" onClick={handleCreateTest}>
                          Create Test with Mistakes
                        </Button>
                      </Card.Body>
                    </Card>
                  )}
                </div>
              )}
            </Col>

            {/* Sidebar Analytics */}
            <Col md={4}>
              <Card className="mb-4">
                <Card.Header>
                  <Card.Title as="h5">Course Analytics</Card.Title>
                </Card.Header>
                <Card.Body>
                  {/* Analytics - Average Score & Tests Taken */}
                  <div className="analytics">
                    <div className="stat">
                      <h6>Average Score</h6>
                      <Badge bg="info">{analyticsData.averageScore}%</Badge>
                    </div>
                    <div className="stat">
                      <h6>Tests Taken</h6>
                      <Badge bg="secondary">{analyticsData.testsTaken}</Badge>
                    </div>
                  </div>

                  {/* Performance Trends Chart */}
                  {hasPerformanceData ? (
                    <div className="chart-container mb-3">
                      <Line
                        data={analyticsData.performanceData}
                        options={{
                          scales: {
                            y: {
                              beginAtZero: true,
                              max: 100,
                              grid: {
                                color: 'rgba(200, 200, 200, 0.3)', // Lighten the grid lines
                                drawBorder: false, // Remove axis border
                              },
                              ticks: {
                                color: '#333', // Change the color of the Y axis labels
                                font: {
                                  family: 'Helvetica Neue', // Keep fonts consistent
                                  size: 12,
                                },
                              },
                            },
                            x: {
                              grid: {
                                display: false, // Hide gridlines for X axis
                              },
                              ticks: {
                                color: '#333', // Change the color of the X axis labels
                                font: {
                                  family: 'Helvetica Neue',
                                  size: 12,
                                },
                              },
                            },
                          },
                          plugins: {
                            legend: {
                              display: true,
                              position: 'top',
                              labels: {
                                color: '#333', // Change the legend text color
                                font: {
                                  family: 'Helvetica Neue',
                                  size: 12,
                                },
                              },
                            },
                            tooltip: {
                              backgroundColor: 'rgba(0,0,0,0.7)', // Dark tooltip background
                              titleFont: { size: 14, family: 'Helvetica Neue' },
                              bodyFont: { size: 12 },
                              padding: 10,
                            },
                          },
                          elements: {
                            line: {
                              tension: 0.4, // Curve the line for a smooth appearance
                              borderWidth: 3, // Thicker line
                              borderColor: 'rgba(52, 152, 219, 1)', // Modern blue for the line
                              fill: 'start', // Fill area under the line
                              backgroundColor: 'rgba(52, 152, 219, 0.2)', // Light fill color
                            },
                            point: {
                              radius: 5, // Bigger points
                              backgroundColor: 'rgba(52, 152, 219, 1)', // Point color
                              borderColor: '#fff', // White border around points
                              borderWidth: 2,
                            },
                          },
                          maintainAspectRatio: false,
                        }}
                      />
                    </div>
                  ) : (
                    <p>No performance data available.</p>
                  )}

                  {/* Topics Performance */}
                  <h6>Topics Performance</h6>
                  <ListGroup className="mb-3">
                    {analyticsData.topicsPerformance &&
                      analyticsData.topicsPerformance.map((topic, index) => (
                        <ListGroup.Item key={index}>
                          {topic.topic}{' '}
                          <Badge bg="info">{topic.score}%</Badge>
                        </ListGroup.Item>
                      ))}
                  </ListGroup>

                  {/* Most Missed Questions */}
                  <h6>Most Missed Questions</h6>
                  <ListGroup>
                    {analyticsData.mostMissedQuestions &&
                      analyticsData.mostMissedQuestions.map((question, index) => (
                        <ListGroup.Item key={index}>
                          {question.question}{' '}
                          <Badge bg="danger">{question.timesMissed} times</Badge>
                        </ListGroup.Item>
                      ))}
                  </ListGroup>
                </Card.Body>
              </Card>
            </Col>

          </Row>
        </div>
        <Footer className="footer course-detail-page" />
      </div>
    </React.Fragment>
  );
}


export default CourseDetail;
